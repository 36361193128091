import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EntKey } from '../ws/model/ws.model';
import { EntData, SRV_ID, SrvResponse } from '../srv.types';
import { MockEntity } from './mock-constructors/entity';

/** Используем данный сервис для замены моковых данных в SrvService */
@Injectable({
    providedIn: 'root',
})
export class MockDataService {
    private mockData: { [key: string]: any } = {
        contract: {
            id: 'mock-contract-id',
            name: 'Mock Contract',
            // Add other properties as needed
        },
        // Add other entity types as needed
    };

    /** Возвращает имитированные данные как будто с сервера. Если есть id то сущность, если нет то список */
    public getMockData$(entityType: EntKey, id: SRV_ID): Observable<SrvResponse> {
        if (!id) return this.getList$(entityType);
        return this.getMockEntity$(entityType, id);
    }

    /** Возвращает обзервэбл имитирующий список сущностей */
    private getList$(entType: EntKey): Observable<SrvResponse> {
        const dataLength = 20;
        let result: SrvResponse = {
            data: [],
            links: null,
            meta: null,
        };
        for (let i = 0; i < dataLength; i++) {
            const item = new MockEntity({
                id: i.toString(),
                type: entType,
                attributes: {
                    // Стандартные поля для всех сущностей
                    dt_create: '2024-09-30T14:55:12.999637+03:00',
                    org_owner_inn: '7777777777',
                    org_owner_name: 'ТЕСТОВАЯ ОРГАНИЗАЦИЯ',
                    status_label: 'draft',
                    status_dt: '2024-09-30T14:55:12.998578+03:00',
                    __str__: 'Тестовый объект',
                },
                relationships: {},
            });
            (result.data as EntData[]).push(item);
        }
        console.log('🚀 ~ MockDataService ~ result:', result);
        return of(result);
    }

    /** Возвращает обзервэбл имитирующий одиночную сущность */
    getMockEntity$(entityType: EntKey, id: SRV_ID): Observable<SrvResponse> {
        const MOCK_ENTITY = { id, type: entityType, attributes: {}, relationships: {} };
        return of({ data: new MockEntity(CONTRACT_EOT) });
    }
}

const CONTRACT_EOT = {
    type: 'contract_eot' as const,
    id: '739',
    attributes: {
        'init_date': '2024-09-20',
        'max_cost': '99000000.00',
        'contract_number': 'ID 739  (ФГУП "ФЭО")',
        'is_treasury': false,
        'bik': '044525225',
        'ks': '30101810400000000225',
        'rs': '40502810338090000044',
        'bank_name': 'ПАО Сбербанк г. Москва',
        'kpp_payment': null,
        'name_recipient': '',
        'ufk': null,
        'treasury_account': '',
        'eks': '',
        'is_fo_branch': true,
        'period_from_date': '2024-09-20',
        'period_to_date': '2024-12-31',
        'is_purchase_winner': true,
        'fkkos': [],
        'fkkos.fkko': '',
        '_org_info': {
            id: 18577,
            status_label: 'accepted',
            is_active: true,
            __str__: 'ФГУП "ФЭО" (ОГРН: 1024701761534, ИНН: 4714004270, КПП: 770601001)',
            name_short: 'ФГУП "ФЭО"',
            inn: '4714004270',
        },
        '_contracts_joined': [],
        'financing_limit_left': '99.99',
        'financing_limit_value': null,
        'financing_limit_left_flat': 98994866.51,
        'offsystem_payment_sum': 0.0,
        'author_name': 'ИМЯ_23022 ФАМИЛИЯ_23022',
        'author_updated_name': 'ИМЯ_23022 ФАМИЛИЯ_23022',
        'dt_create': '2024-09-20T10:40:50.152609+03:00',
        'dt_update': '2024-09-30T15:13:23.864944+03:00',
        '__str__': 'Исполнитель: ФГУП "ФЭО" (ОГРН: 1024701761534, ИНН: 4714004270, КПП: 770601001)',
        'org_owner_name': 'ФГУП "ФЭО"',
        'org_owner_inn': '4714004270',
        'note': null,
        'status_author_name': 'ИМЯ_23022 ФАМИЛИЯ_23022',
        'status_label': 'active',
        'status_dt': '2024-09-20T10:48:22.223628+03:00',
        'status_history': [
            {
                dt: '2024-09-20T07:48:22.223628+00:00',
                files: [],
                label: 'active',
                author: 'Ленияра Ф',
                comment: 'Технический договор для транспортирования ПХБ-отходов',
            },
            {
                dt: '2024-09-20T07:40:50.151981+00:00',
                label: 'draft',
                author: 'Ленияра Ф',
            },
        ],
        'is_active': true,
    },
    relationships: {
        fkkos: {
            meta: {
                count: 4,
            },
            data: [
                {
                    type: 'contract_transportation_fkko',
                    id: '6583',
                },
                {
                    type: 'contract_transportation_fkko',
                    id: '6584',
                },
                {
                    type: 'contract_transportation_fkko',
                    id: '6585',
                },
                {
                    type: 'contract_transportation_fkko',
                    id: '6586',
                },
            ],
        },
        org_info: {
            data: {
                type: 'organization_info',
                id: '18577',
            },
        },
        contracts_joined: {
            meta: {
                count: 0,
            },
            data: [],
        },
        contact_detail: {
            meta: {
                count: 0,
            },
            data: [],
        },
        contactdetail_contract_transportation: {
            meta: {
                count: 0,
            },
            data: [],
        },
        author: {
            data: {
                type: 'user',
                id: '23022',
            },
        },
        author_updated: {
            data: {
                type: 'user',
                id: '23022',
            },
        },
        org_owner: {
            data: {
                type: 'organization',
                id: '663',
            },
        },
        status_author: {
            data: {
                type: 'user',
                id: '23022',
            },
        },
    },
    meta: {
        allow_transits: ['contract-eot#active_to_draft', 'contract-eot#active_to_inactive'],
    },
};
