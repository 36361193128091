// Здесь константы, которые в дев. режиме требуется подменить на девелоперские.
// Тогда мы берем const.dev.ts
export const DASHBOARD_BASE_URL = '/cubejs-api/v1/load';
export const WEBAPI_URL = '/webapi/v1/';

/** В дев режиме используется для добавления UI-разрешений Здесь не менять. Разрешения добавляются в файле const.dev.ts */
export const EXTRA_PERMISSIONS = null;

/** В дев режиме используется для замены сущностей на моковые в сервисе SrvService. Здесь не менять. Сущности добавляются в файле const.dev.ts */
export const ENTITIES_TO_MOCK = [];
