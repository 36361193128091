import {
    EntData,
    MultipleRelation,
    RelationData,
    SingleRelation,
    SRV_Attributes,
    SRV_ID,
    SRV_Meta,
    SRV_RelationshipsCommon,
} from 'src/app/srv.types';
import { EntKey } from 'src/app/ws/model/ws.model';
import { getMockSchema } from './schema';

export interface MockBaseEntityInterface {
    id: SRV_ID;
    type: EntKey;
}

export interface MockEntityInterface extends EntData {}

export function mockRelation<T extends RelationData | RelationData[]>(
    data: T,
): T extends RelationData[] ? MultipleRelation : SingleRelation {
    if (!data) throw new Error('Непредоставлены данные для relationships');
    if (data instanceof Array) {
        return { data, meta: { count: data.length } } as T extends RelationData[] ? MultipleRelation : SingleRelation;
    }
    return { data } as T extends RelationData[] ? MultipleRelation : SingleRelation;
}

/** Создает минимальный общий объект сущности с обычными полями */
export class MockBaseEntity {
    constructor(
        public id: SRV_ID,
        public type: EntKey,
    ) {}
}

/** моделирует создание экземпляров объектов типа EntData */
export class MockEntity<T extends MockEntityInterface> extends MockBaseEntity {
    id: SRV_ID;
    type: EntKey;
    attributes: any & SRV_Attributes;
    meta: SRV_Meta = { schema: {} };
    relationships: SRV_RelationshipsCommon;

    constructor(data: T) {
        super(data.id, data.type);

        // Dynamically add other fields
        Object.keys(data).forEach((key) => {
            if (key !== 'id' && key !== 'type') {
                (this as any)[key] = (data as any)[key];
            }
        });

        const schemaFields = [...Object.keys(data), ...Object.keys(data.attributes)].filter(
            (key) => !['attributes', 'relationships'].includes(key),
        );
        this.meta.schema = getMockSchema([...schemaFields, '_meta']);
    }
}
