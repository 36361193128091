import { FieldSchemaModality, FieldSchemaType, FieldsSchema } from 'src/app/srv.types';

export function getMockSchema(fieldKeys: string[]): FieldsSchema {
    let schema: FieldsSchema = {};
    fieldKeys.forEach((key) => {
        schema[key] = Object.assign({}, DUMMY_CONFIG);
        schema[key].name = key;
    });
    return schema;
}

export const DUMMY_CONFIG = {
    empty_value: '',
    modality: 'optional' as FieldSchemaModality,
    name: 'Тестовое поле',
    readonly: false,
    regexp: [],
    type: 'string' as FieldSchemaType,
};
